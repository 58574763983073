
import heic2any from "heic2any";

const MAX_WIDTH = 600;
const MAX_HEIGHT = 600;

function resizeImage(file, resolveUrl) {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();
        reader.onload = function (readerEvent) {
            const image = new Image();
            image.onload = function (imageEvent) {

                const canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);

                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (!blob) {
                        console.log("canvas is empty");
                        reject();
                    } else {
                        if (resolveUrl) {
                            var fileUrl = window.URL.createObjectURL(blob);
                            resolve(fileUrl);
                        } else {
                            const resizedFile = new File([blob], file.name, {type: file.type});
                            resolve(resizedFile);
                        }
                    }
                })

            }
            image.src = readerEvent.target.result;
        }

        reader.readAsDataURL(file);
    })
}

async function convertToJpeg(file) {
    try {
        const blob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.9, // optional
        });

        const convertedFile = new File(
            [blob],
            file.name.replace(/\.(heic|heif)$/i, ".jpg"),
            { type: "image/jpeg" }
        );

        return convertedFile;
    } catch (error) {
        console.error("Failed to convert HEIC/HEIF to JPEG:", error);
        throw error;
    }
}

export {
    resizeImage,
    convertToJpeg,
}