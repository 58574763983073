import { useRef, useState } from 'react';
import Button from '../UiComponents/Button';
import { uploadImage } from '../../scripts/storage';
import { resizeImage, convertToJpeg } from './utils';
import { loadingStates } from '../installation/installationTypes';
import LoadingSpinner from '../UiComponents/LoadingSpinner';
import FileInputButton from './FileInputButton';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    color: ${theme.colors.textGray};
`

const ImageUpload = ({ customerId, onUpload }) => {
    const inputRef = useRef();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [errorMessage, setErrorMessage] = useState();

    const onFileSelect = async (event) => {
        const fileList = Array.from(event.target.files || []);
        if (!fileList.length) return;

        setErrorMessage(null);
        setLoadingState(loadingStates.NONE)

        const newFileList = [];
        let validated = true;

        for (let i = 0; i < fileList.length; i++) { /// This is where the error occurs
            let file = fileList[i];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (['heic', 'heif'].includes(fileExtension)) {
                try {
                    file = await convertToJpeg(file); 
                } catch (e) {
                    validated = false;
                    break;
                }
            }

            const validFormats = ['png', 'jpg', 'jpeg', 'pdf'];
            const currentExtension = file.name.split('.').pop().toLowerCase();
            
            if (!validFormats.includes(currentExtension)) {
                validated = false;
                break;
            }

            newFileList.push(file);
        }

        if (!validated) {
            inputRef.current.value = null;
            setErrorMessage('Error: wrong format');
        } else {
            upload(newFileList);
        }
    };

    const upload = async (files) => {
        try {
            setLoadingState(loadingStates.LOADING);
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`image`, file);
            })

            const uploadRes = await uploadImage(customerId, formData);
            if (onUpload) {
                onUpload(uploadRes);
            }
            if (inputRef?.current) {
                inputRef.current.value = null;
            }
            setLoadingState(loadingStates.SUCCESS);
            setTimeout(() => {
                setLoadingState(loadingStates.NONE);
            }, 2000);
        } catch (err) {
            console.log('err', err);
            setErrorMessage('Upload failed');
            setLoadingState(loadingStates.FAILED);
        }
    }

    return (
        <Container>
            <div>
                {loadingState === loadingStates.LOADING ? <>
                    <LoadingSpinner /><span>Uploading...</span>
                </> : <>
                    <FileInputButton
                        inputRef={inputRef}
                        onFileSelect={onFileSelect}
                    />
                </>}

                {loadingState === loadingStates.SUCCESS ? <>
                    <FaCheckCircle style={{marginRight: '4px'}}/><span>Uploaded</span>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <span>Upload failed</span>
                </> : <></>}
            </div>

            <div>
                {errorMessage}
            </div>
        </Container>
    );
}

export default ImageUpload;