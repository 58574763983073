import { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import AnimateHeight from "react-animate-height";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import InnerCard from "../../../UiComponents/containers/InnerCard";
import ExpandCollapseButton from "../../../UiComponents/buttons/ExpandCollapseButton";
import Button from "../../../UiComponents/Button";
import { createVenueConnectionCode, getVenueConnectionCode } from "../../../../scripts/client";

const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        cursor: pointer;
    }

    h4 {
        margin: 16px 0;
    }
`

const InfoText = styled(TextMuted)`
    margin: 10px 0; 
`

const StyledLink = styled.a`
    color: ${theme.colors.greenReadable};
`

const ErrorMessage = styled.label`
    margin: 10px 0;
    color: ${theme.colors.raspberryRed};
    padding: 0;
`

const ButtonCont = styled(ButtonContainer)`
    margin-bottom: 10px;
`

const ApiKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 40px;
    gap: 5px; 

    h4 {
        margin: 0;
    }

    h5 {
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.15rem;
        background: linear-gradient(90deg, ${theme.colors.greenEnergy}, ${theme.colors.crystalBlue});
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: fit-content;
        margin: 0;
    }

    h6 {
        font-size: 0.9rem;
        font-weight: bold;
        letter-spacing: 0.15rem;
        color: ${theme.colors.darkSpace20};
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
`



const ApiKeyForm = ({customerId, venue, refresh}) => {

    const [expanded, setExpanded] = useState();
    const [error, setError] = useState('');
    const [venueConnectionCode, setVenueConnectionCode] = useState('');

    useEffect(() => { fetchVenueConnectionCode() }, []);

    const fetchVenueConnectionCode = async () => {
        const code = await getVenueConnectionCode(customerId);
        if (code) {
            setVenueConnectionCode(code);
        } 
    }

    const generateVenueConnectionCode = async () => {
        const code = await createVenueConnectionCode(customerId);
        if (code) {
            setVenueConnectionCode(code);
        } else {
            setError('Could not generate a new Venue Connection Code');
        }
    }

    return <StyledCard>
        <NavigationContainer onClick={() => setExpanded(prev => { return !prev })}>
            <h4>Venue Connection Code</h4>
            <ExpandCollapseButton showState={expanded} color={theme.colors.darkSpace60} />
        </NavigationContainer>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <Container>
                <InfoText>
                    Your Venue Connection Code key lets trusted partners access your data to enhance their services for you.
                    After sharing it, they must register at <StyledLink href="https://api3.waved.app" target="_blank">api3.waved.app</StyledLink>.
                    Once registered, they can use the key to access your data.
                </InfoText>

                {venueConnectionCode?
                    <ApiKeyContainer>
                        <h4>Your Venue Connection Code: </h4>
                        <h5>{venueConnectionCode}</h5>
                    </ApiKeyContainer>
                : 
                    <ApiKeyContainer>
                        <h6>You don't have a Venue Connection Code.<br/>
                        Create a new Venue Connection Code to let partners access your data.</h6>
                        <Button primary onClick={generateVenueConnectionCode}>Create New Venue Connection Code</Button>
                    </ApiKeyContainer>
                } 

                { venueConnectionCode && <>
                    <InfoText>
                        IMPORTANT: Share your Venue Connection Code only with trusted partners. <br/>
                        If compromised, generate a new one to invalidate the old key.
                    </InfoText>
                    <ButtonCont>
                        <Button primary onClick={generateVenueConnectionCode}>Create New Venue Connection Code</Button>
                        {/*<Button secondary>Revoke Venue Connection Code</Button>*/}
                    </ButtonCont>
                </>}

                <ErrorMessage>{error}</ErrorMessage>
            </Container>
        </AnimateHeight>

    </StyledCard>
}

export default ApiKeyForm;