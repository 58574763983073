import React, {useState} from 'react';
import Dropdown from '../../../UiComponents/Dropdown';


const venueItems = [
    { label: 'Other' },
    { label: 'Restaurant' },
    { label: 'Bar' },
    { label: 'Cafe' }
];


const VenueTypeDropdown = ({venue, editing, editedInfo, setEdited}) => {

    const [selectedItem, setSelectedItem] = useState(venueItems.find(item => item.label === venue?.type) || venueItems[0]);

    const onSelectHandler = (item) => {
        setSelectedItem(item);
        setEdited(true);
        if (editedInfo) {
            editedInfo.type = item.label;
        }
    }

    return (
        <div>
            <label>Venue Type</label>
            {editing ? (
                <Dropdown
                    placeholder={'Select Venue Type'}
                    items={venueItems}
                    selectedItem={selectedItem}
                    clearable={false}
                    onClick={(item) => onSelectHandler(item)}
                    style={{ width: '100%' }}
                    dropdownStyle={{ width: '100%' }}
                />
            ) : (
                <p>{selectedItem.label}</p>
            )}


        </div>
    )
}

export default VenueTypeDropdown;