import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/client';

async function getAppVersion() {
    try {
        const res = await axios.get(`${hostname}/v2/app/version`);
        if (res.data.version != undefined) {
            return res.data.version;
        }
    } catch (e) {
        console.log('Could not get app version');
        return false;
    }
}

async function updateGoogleOpeningHours(customerId, googlePlaceId) {
    try {
        const res = await axios.post(`${hostname}${path}/newGoogleOpeningHours?customerId=${customerId}&googlePlaceId=${googlePlaceId}`, {});
        if (res.data.error) {
            return false;
        }
        if (res.data) {
            return res.data;
        }
    } catch (e) {
        console.log('Could not update google opening hours');
        return false;
    }
}

async function createVenueConnectionCode(customerId) {
    try {
        const res = await axios.post(`${hostname}${path}/createVenueConnectionCode?customerId=${customerId}`);
        if (res.data.venueConnectionCode) {
            return res.data.venueConnectionCode;
        }
    } catch (e) {
        console.log('Could not create venue connection code');
        return false;
    }
}

async function getVenueConnectionCode(customerId) {
    try {
        const res = await axios.get(`${hostname}${path}/venueConnectionCode?customerId=${customerId}`);
        if (res.data.venueConnectionCode) {
            return res.data.venueConnectionCode;
        }
    } catch (e) {
        console.log('Could not get venue connection code');
        return false;
    }
}

export {
    updateGoogleOpeningHours,
    getAppVersion,
    createVenueConnectionCode,
    getVenueConnectionCode,
}