import React, { useState, useEffect } from "react"
import useServerStatus from '../../hooks/useServerStatus';
import useNetwork from "../../hooks/useNetwork";
import TroubleshootInternet from "./TroubleshootInternet";
import TroubleshootHubOffline from "./TroubleshootHubOffline";
import TroubleshootProcessor from "./TroubleshootProcessor";
import TroubleshootServer from "./TroubleshootServer";
import TroubleshootHubError from "./TroubleshootHubError";
import { MAX_OFFLINE_TIME } from "./utils";

const ClientTroubleshooting = (props) => {

    const serverStatus = useServerStatus();
    const networkStatus = useNetwork();
    const [usesEthernet, setUsesEthernet] = useState(true);
    const [localApp, setLocalApp] = useState(false);
    const [notOkHubs, setNotOkHubs] = useState([]);
    const [offlineHubs, setOfflineHubs] = useState([]);
    const [disconnectedProcessors, setDisconnectedProcessors] = useState([]);

    useEffect(() => {
        checkLocalApp();
        checkUsesEthernet();
    }, [])

    useEffect(() => {
        checkHubOffline();
        checkNotOkHubs();
    }, [props.hubs]);

    useEffect(() => {
        checkDisconnectedProcessors();
    }, [props.processors]);

    function checkLocalApp() {
        const url = window.location.href;
        if (url.includes('waved') || process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'){
            setLocalApp(false);
        } else setLocalApp(true)
    } 

    function checkUsesEthernet() {
        const hubs  = props.hubs.filter((hub) => hub.networkConfig?.eth0?.address);
        if (hubs.length > 0) setUsesEthernet(true);
        else setUsesEthernet(false);
    }

    function checkNotOkHubs() {
        const faultyHubs = props.hubs.filter((hub) => hub.hubOK === false);
        if (faultyHubs.length > 0) setNotOkHubs(faultyHubs);
        else setNotOkHubs([]);
    }

    function checkHubOffline () {
        const offline = props.hubs.filter((hub) => hub.secondsSinceLastOnline > MAX_OFFLINE_TIME);
        if (offline) setOfflineHubs(offline);
        else setOfflineHubs([]);
    }

    function checkDisconnectedProcessors() {
        const faultyProcessors = props.processors.filter((processor) => processor.isConnected === 0);
        if (faultyProcessors.length > 0) setDisconnectedProcessors(faultyProcessors);
        else setDisconnectedProcessors([]);
    }

    if ( networkStatus.online === false && localApp === false ) {
        return(
            <TroubleshootInternet
                hubs={props.hubs}
            />
        )
    } else if ( serverStatus === false ) {
        return (
            <TroubleshootServer/>
        )
    } else if ( offlineHubs.length > 0 ) {
        return(
            <TroubleshootHubOffline
                offlineHubs={offlineHubs}
                usesEthernet={usesEthernet}
            />
        )
    } else if ( notOkHubs.length > 0 ) {
        return (
            <TroubleshootHubError
                notOkHubs={notOkHubs}
                usesEthernet={usesEthernet}
            />
        )
    } else if ( disconnectedProcessors.length > 0 ) { 
        return(
            <TroubleshootProcessor
                hubs={props.hubs}
                disconnectedProcessors={disconnectedProcessors}
            />
        )
    } else {
        return <></>
    }
}

export default ClientTroubleshooting;