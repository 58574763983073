import styled, { css } from "styled-components";
import { ButtonRowContainer, ModalHeader, ModalText } from "../../../../panel/ModalStyles";
import { getItemIcon } from "../../../styling/Icons";
import theme from "../../../../../UI/theme";
import { useEffect, useState } from "react";
import { createOrUpdateHeatmapFloorElement, getHeatmapFloorElement } from "../../../../../scripts/heatmapFloorElement";
import Button from "../../../../UiComponents/Button";
import DocumentationPhotos from "./DocumentationPhotos";
import { useRef } from "react";

const Container = styled.div`
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    overflow: auto;

    ${props => props.maxHeight && css`
        max-height: ${props.maxHeight}px;
    `}
`

const Header = styled(ModalHeader)`
    display: flex;
    align-items: center;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};

    &>svg {
        margin-right: 10px;
    }
`

const DescriptionTextArea = styled.textarea`
    all: unset;
    box-sizing: border-box;
    width: 100%;
    background-color: ${theme.colors.whiteSpace};
    color: ${theme.colors.darkSpace};
    padding: 10px;
    border-radius: 2px;
    resize: vertical;
    white-space: pre-wrap;
    min-height: 100px;

    ${props => props.disabled && css`
        background-color: ${theme.colors.darkSpace80};
        color: ${theme.colors.textGray};
    `}
`

const InstallationItemInfo = props => {
    const ItemIcon = getItemIcon(props.selectedItem?.type);
    const [heatmapFloorElement, setHeatMapFloorElement] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState('');
    const containerRef = useRef();
    const [maxHeight, setMaxHeight] = useState();

    useEffect(() => {
        fetchHeatmapFloorElement(props.selectedItem?.id)
        setIsEditing(false);
    }, [props.selectedItem?.id])

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            if (props.troubleshooting === true) setMaxHeight(false); 
            else setMaxHeight(Math.max(window.innerHeight - entries[0]?.target?.getBoundingClientRect()?.top - 20, 0));
        });
        observer.observe(containerRef.current);

        return () => containerRef?.current && observer.unobserve(containerRef.current);
    }, [])

    const fetchHeatmapFloorElement = async (floorElementId) => {
        if (floorElementId) {
            try {
                const linkedHeatmapFloorElement = await getHeatmapFloorElement(floorElementId, props.customerId);
                setHeatMapFloorElement({ type: props.selectedItem?.type, floorElementId: floorElementId, ...linkedHeatmapFloorElement });
                setDescription(linkedHeatmapFloorElement?.description ?? '');
            } catch (err) {
                setHeatMapFloorElement({ type: props.selectedItem?.type, floorElementId });
                setDescription('');
            }
        } else {
            setHeatMapFloorElement(null);
            setDescription('');
        }
    }

    const onChangeDescription = (newDescription) => {
        setDescription(newDescription);
    }

    const onDiscardChanges = () => {
        setDescription(heatmapFloorElement?.description ?? '');
        setIsEditing(false);
    }

    const saveDescription = () => {
        saveChanges({ description });
    }

    const saveChanges = async (updatedProps) => {
        let newHeatmapFloorElement = JSON.parse(JSON.stringify(heatmapFloorElement));
        newHeatmapFloorElement = { ...newHeatmapFloorElement, ...updatedProps };
        const updatedHeatmapFloorElement = await createOrUpdateHeatmapFloorElement(props.customerId, newHeatmapFloorElement);
        setHeatMapFloorElement(updatedHeatmapFloorElement);
        setDescription(updatedHeatmapFloorElement?.description);
        setIsEditing(false);
    }

    return <Container ref={containerRef} maxHeight={maxHeight}>
        {props.selectedItem ? <>
            <Header><ItemIcon />{props.selectedItem?.name}</Header>

            <ModalText>{props.selectedItem?.type}</ModalText>

            <ModalText>Description</ModalText>

            <DescriptionTextArea rows={8} value={description} onChange={(e) => onChangeDescription(e.target.value)} disabled={!isEditing} />

            {(props.readOnly === true)?
                <></>
            :
                <ButtonRowContainer>
                    {isEditing ? <>
                        <Button primary small onClick={() => saveDescription()}>Save</Button>
                        <Button secondary small onClick={() => onDiscardChanges()}>Discard changes</Button>
                    </> : <>
                        <Button secondary small onClick={() => setIsEditing(true)}>Edit</Button>
                    </>}
                </ButtonRowContainer>
            }
            

            <DocumentationPhotos
                customerId={props.customerId}
                heatmapFloorElement={heatmapFloorElement}
                saveUpdatedFloorElement={saveChanges}
                readOnly={props.readOnly}
            />
        </> : <></>}
    </Container>
}

export default InstallationItemInfo;